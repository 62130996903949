<template>
  <div
    class="overflow-hidden py-8 min-h-100vh-mobile-menu md:min-h-100vh-menu md:pt-40 md:pb-48"
  >
    <div class="content-container">
      <h1
        class="main-title uppercase font-extrabold overflow-hidden font-pragmatica-extended leading-none"
      >
        {{ $t('hsc.post.blog') }}
      </h1>

      <controller-post-category-list
        class="mt-4 mb-20 flex justify-end items-center text-1.4rem sm:mb-32 lg:mb-40"
      >
        <template #list="{ items }">
          <select
            class="font-pragmatica-extended bg-white focus:outline-none"
            @change="
              $event.target.value === 'blog'
                ? $router.push('/blog')
                : $router.push(
                    `${localePath($Page.Blog)}/${$event.target.value}`
                  )
            "
          >
            <option selected value="blog">{{ $t('hsc.post.filter') }}</option>
            <option
              v-for="item in items"
              :key="item.item.id"
              :value="item.item.slug"
            >
              {{
                item.item.slug === 'featured'
                  ? $t('hsc.post.featured')
                  : item.item.title
              }}
            </option>
          </select>
        </template>
      </controller-post-category-list>
      <controller-post-list
        v-slot="{
          items,
          options,
          paginationState,
          previousPage,
          nextPage,
          firstPage,
          lastPage,
          status,
        }"
        limit="9"
      >
        <div
          class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 justify-items-stretch items-stretch gap-x-6 gap-y-24"
        >
          <div
            v-for="item in items"
            :key="item.id"
            class="flex flex-col justify-between"
          >
            <div class="flex-shrink-0 mb-6 md:mb-10">
              <cms-link :to="item">
                <cms-image
                  :media="item.media"
                  class="w-full h-64 mb-6 object-cover object-center md:mb-10"
                />
              </cms-link>

              <h3
                class="two-line-truncate responsive-2-line-heading font-pragmatica-extended font-bold"
              >
                <cms-link :to="item" class="hover:underline">
                  {{ item.title }}
                </cms-link>
              </h3>
            </div>

            <div
              class="flex flex-col flex-grow justify-between text-xl md:text-1.4rem"
            >
              <p
                class="four-line-truncate leading-relaxed mb-6 text-xl md:text-1.4rem md:mb-3.5rem"
              >
                {{ item.excerpt ? item.excerpt : '' }}
              </p>

              <div class="flex items-center justify-between">
                <cms-link :to="item" class="font-bold hover:underline"
                  >{{ $t('hsc.post.readMore') }}
                </cms-link>
                <button
                  class="inline-flex items-center leading-none focus:outline-none"
                  @click="toggleSocialShare(item)"
                >
                  <span>{{ $t('hsc.post.share') }}</span>
                  <fa
                    :icon="icons.basic.share"
                    class="ml-3 text-2xl transitioned"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>

        <pagination
          v-if="paginationState.totalPages > 1"
          class="mt-16 md:mt-24"
          :options="options"
          :pagination-state="paginationState"
          :previous-page="previousPage"
          :next-page="nextPage"
          :first-page="firstPage"
          :last-page="lastPage"
          :disabled="status.pending"
        />
      </controller-post-list>
      <transition name="fade-200ms">
        <client-only>
          <social-share-modal
            v-if="socialShareModalActive"
            :endpoint="postToShare ? postToShare.slug : ''"
            @closeSocialShare="closeSocialShare"
          />
        </client-only>
      </transition>
    </div>
  </div>
</template>

<script>
import ControllerPostList from 'Controllers/ControllerPostList'
import ControllerPostCategoryList from 'Controllers/ControllerPostCategoryList'
import SocialShareModal from '@/components/ui/social/SocialShareModal'
import FaIcons from '@/mixins/FaIcons'
import Pagination from '@/components/ui/pagination/Pagination'

export default {
  components: {
    ControllerPostList,
    ControllerPostCategoryList,
    SocialShareModal,
    Pagination,
  },
  mixins: [FaIcons],
  data() {
    return {
      socialShareModalActive: false,
      postToShare: null,
    }
  },
  methods: {
    toggleSocialShare(post) {
      this.socialShareModalActive
        ? this.closeSocialShare()
        : this.openSocialShare(post)
    },
    openSocialShare(post) {
      this.postToShare = post
      this.socialShareModalActive = true
    },
    closeSocialShare() {
      this.postToShare = null
      this.socialShareModalActive = false
    },
  },
}
</script>
<style scoped>
button:hover svg {
  @apply text-pureYellow transform scale-125;
}

a:hover svg {
  @apply text-pureYellow transform scale-125;
}

.responsive-2-line-heading {
  --lh: 1.5;
  --max-lines: 2;
  --font-size: 1.5rem;
  font-size: var(--font-size);
  line-height: calc(var(--lh) * var(--font-size));
}

@media only screen and (min-width: 640px) {
  .responsive-2-line-heading {
    height: calc(var(--lh) * var(--font-size) * var(--max-lines));
  }
}

@media only screen and (min-width: 768px) {
  .responsive-2-line-heading {
    --font-size: 1.875rem;
  }
}

@media only screen and (min-width: 1024px) {
  .responsive-2-line-heading {
    --font-size: 2rem;
  }
}
</style>
